import React from "react";

const ListOfNames = () => {
    const names = [
        "Adam Birkhold",
        "Al Motlagh",
        "Alan D Daneshrad",
        "Ali A Azarakhsh",
        "Ali Awad",
        "Ali Razavi",
        "Alina Bagasian",
        "Alla Tenina",
        "Ameer Shah",
        "Andrew D Kumar",
        "Andrew Zeytuntsyan",
        "Anthony Choe",
        "Aram Rostomyan",
        "Aren Manukyan",
        "Ari Moss",
        "Arin Khodaverdian",
        "Aron C Movroydis",
        "Artin Sookasian",
        "Ashkan Minaie",
        "Ayesha Rafi",
        "Barry H Hinden",
        "Ben Dominguez II",
        "Benjamin Fogel",
        "Benjamin Khakshour",
        "Bita N Haiem",
        "Bobby B Saadian",
        "Bobby Tamari",
        "Brian Banner",
        "Brian C Mitchell",
        "Cagney McCormick",
        "Cameron Y Brock",
        "Christopher Bragoli",
        "Christopher Culleton",
        "Clifford J Enten",
        "D. Scott Warmuth",
        "Dan Abir",
        "Daniel A Reisman",
        "Daniel Bottari",
        "Daniel J Rafii",
        "Darren Miller",
        "David Benn",
        "David E Jacobson",
        "David F Makkabi",
        "David Krangle",
        "David Kreizer",
        "David L Issapour",
        "David P Bonemeyer",
        "David P Kashani",
        "David Yerushalmi",
        "Derek Lee",
        "Edward Herman",
        "Edward Okwueze",
        "Edward Ramsey",
        "Elliot Zarabi",
        "Eric Mausner",
        "Erik Zograbian",
        "Felicia B Edelman",
        "Fletcher B Brown",
        "Gary Berkovich",
        "Gary K Daglian",
        "Geoffrey P Norton",
        "George Jawlakian",
        "George P Escobedo",
        "George P Hakim",
        "George Salinas",
        "Gerry Hernandez",
        "Gil Alvandi",
        "Goldwater Partner",
        "Gordon McKernan",
        "Granth J Crhoelman",
        "Gus Anastopoulo",
        "Hagop Chopurian",
        "Harout A Messrelian",
        "Irina Martirosyan",
        "James A Allaire",
        "James Kim",
        "James Onder",
        "James Shaw",
        "James White",
        "Jared S Zafran",
        "Jared Spingarn",
        "Jason B Chalik",
        "Jason Javaheri",
        "Jeffrey Knoll",
        "Jerrold Parker",
        "Jerry Jacobson",
        "Jimmy H Jin",
        "John Brockmeier",
        "John C Ye",
        "John Hong",
        "John Leo",
        "Johnny G Phillips",
        "Jonathan I Rotstein",
        "Jonathan Melmed",
        "Jonathan Yagoubzadeh",
        "Joseph Nazarian",
        "Joseph S Nourmand",
        "Joshua J Zokaeem",
        "Justin Farahi",
        "Justin L Lawrence",
        "Kaveh Elihu",
        "Kenny Habetz",
        "Kevin A Garcia",
        "Kevin Butler",
        "Kevin Danesh",
        "Kevin Jani",
        "Kevin Moore",
        "Khalil Khan",
        "Kian Mottahedeh",
        "Kyle Madison",
        "Mahdis Kaeni",
        "Maralle Messrelian",
        "Marc Pacin",
        "Marielys Acosta",
        "Mark Sweet",
        "Martin Arteaga",
        "Matt Koohanim",
        "Matthew Buzzell",
        "Michael Avanesian",
        "Michael Emrani",
        "Michael Fielding",
        "Michael Ghozland",
        "Michael H Kim",
        "Michael Pierce",
        "Michael Saeedian",
        "Michael Steinger",
        "Miguel I Alvarez",
        "Mohammad (Mo) Abuershaid",
        "Nassir N Ebrahimian",
        "Nathaniel Preston",
        "Nilufar Alemozaffar",
        "Omid Razi",
        "Pavel Sterin",
        "Payam Tishbi",
        "Pouya Chami",
        "Ramin Kermani-Nejad",
        "Randal Klezmer",
        "Raphael B Hedwat",
        "Raymond Ghermezian",
        "Ricardo Y Merluza",
        "Rob A Rodriguez",
        "Robert M Pave",
        "Robin Saghian",
        "Robinson S Rowe",
        "Ronald DeSimone",
        "Ronen Kleinman",
        "Rouben Varozian",
        "Ryan Banafshe",
        "Sam Almasri",
        "Samuel Ceballos",
        "Sanam Salimnia Aghnami",
        "Scott Diallo",
        "Scott E Wheeler",
        "Sean Logue",
        "Sean Simpson",
        "Sef Krell",
        "Servando Timbol",
        "Seymone Javaherian",
        "Sharif Alkalbani",
        "Shawn Azizzadeh",
        "Shervin Lalezary",
        "Siamak Vaziri",
        "Stacy Kemp",
        "Stephan Airapetian",
        "Stephen Godwin",
        "Stephen Kwan",
        "Thomas A Cifarelli",
        "Thomas Combs",
        "Thomas G Kemerer",
        "Tigran Martinian",
        "Troy T Otus",
        "Vivian N Szawarc",
        "Yasmin Azimi",
    ];
    return (
        <div className="flex flex-col p-5">
            <p className="text-2xl self-start font-bold w-full border-b mb-4 border-[#999]">
                Sponsors
            </p>
            <div className="flex flex-col">
                {names.map((name) => (
                    <p key={name}>- {name}</p>
                ))}
            </div>
        </div>
    );
};

export default ListOfNames;
