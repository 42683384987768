import React, { ReactElement } from "react";

export default function InjuryCompensationLogo(): ReactElement {
    return (
        <svg
            width="262"
            height="35"
            viewBox="0 0 262 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M38.7194 28.0196H32.1221V26.7948H34.1659V13.6078H32.1221V12.3455H38.7194V13.6078H36.6756V26.7948H38.7194V28.0196Z"
                fill="black"
            />
            <path
                d="M54.4837 28.0195H48.991V26.8924H50.4938V21.2945C50.5419 20.3449 50.3561 19.398 49.9528 18.5369C49.7515 18.2015 49.4586 17.9306 49.1084 17.7562C48.7583 17.5818 48.3656 17.5112 47.9766 17.5526C47.5841 17.5481 47.1942 17.6169 46.827 17.7555C46.4865 17.8849 46.1673 18.0647 45.8802 18.289C45.5909 18.512 45.3375 18.7781 45.1288 19.0779C44.9285 19.3655 44.7739 19.6823 44.6705 20.0172V26.9225H46.1733V28.0195H40.6505V26.8924H42.3111V17.3948H40.6505V16.2752H44.6705V18.6045C45.0127 17.883 45.5291 17.2581 46.1733 16.7862C46.8734 16.2786 47.7207 16.0146 48.5852 16.0348C49.1905 16.0257 49.7943 16.0964 50.3811 16.2452C50.8771 16.3722 51.3291 16.632 51.6885 16.9966C52.0708 17.4285 52.3485 17.9428 52.5 18.4993C52.7021 19.2844 52.7956 20.0934 52.778 20.9038V26.915H54.4386L54.4837 28.0195Z"
                fill="black"
            />
            <path
                d="M57.5794 17.3948H55.9188V16.2753H59.9313V26.6595C59.9621 27.6073 59.8064 28.552 59.4729 29.4397C59.2139 30.0911 58.8455 30.6934 58.3834 31.2205C58.0248 31.6274 57.5975 31.9682 57.121 32.2274C56.799 32.399 56.4651 32.5472 56.1217 32.6707L55.5131 31.7014C55.746 31.589 55.9653 31.4503 56.1668 31.2881C56.4343 31.0635 56.6606 30.794 56.8355 30.4917C57.0742 30.0734 57.2513 29.623 57.3615 29.1542C57.5202 28.4823 57.5934 27.7931 57.5794 27.1029V17.3948ZM57.0609 12.6385C57.053 12.4432 57.0902 12.2487 57.1697 12.0702C57.2492 11.8916 57.3688 11.7338 57.5193 11.6091C57.8539 11.3441 58.2725 11.2081 58.699 11.2259C59.1332 11.2012 59.5613 11.3376 59.9012 11.6091C60.0391 11.7435 60.1487 11.9042 60.2236 12.0816C60.2984 12.259 60.337 12.4497 60.337 12.6423C60.337 12.8348 60.2984 13.0255 60.2236 13.2029C60.1487 13.3803 60.0391 13.541 59.9012 13.6754C59.559 13.9417 59.1319 14.0751 58.699 14.0511C58.2739 14.0677 57.8565 13.9348 57.5193 13.6754C57.3682 13.5495 57.2483 13.3905 57.1688 13.2106C57.0893 13.0307 57.0524 12.835 57.0609 12.6385Z"
                fill="black"
            />
            <path
                d="M64.1692 23.3835C64.1692 22.3991 64.1692 21.3848 64.2368 20.3779C64.3044 19.371 64.3044 18.3566 64.3044 17.4174H62.5988V16.2753H66.6337C66.6337 17.4399 66.5811 18.5295 66.5586 19.6265C66.5361 20.7235 66.5586 21.8055 66.5586 22.9552C66.553 23.5003 66.5857 24.0451 66.6563 24.5857C66.7073 25.0046 66.8373 25.41 67.0395 25.7804C67.2088 26.1007 67.4706 26.3625 67.7909 26.5318C68.184 26.7114 68.6138 26.7963 69.0457 26.7798C69.393 26.7852 69.7379 26.7215 70.0603 26.5925C70.3828 26.4635 70.6765 26.2718 70.9242 26.0284C71.4704 25.493 71.8735 24.829 72.0964 24.0973V17.3347H70.4583V16.2753H74.4633V26.9225H76.1239V28.0196H72.3669L72.2166 25.4573H72.1039C72.0136 25.8037 71.8636 26.1317 71.6606 26.4266C71.4376 26.7646 71.1639 27.0661 70.8491 27.3208C70.5024 27.5993 70.1146 27.8224 69.6994 27.982C69.2499 28.1597 68.7702 28.249 68.2868 28.245C67.691 28.2534 67.0969 28.18 66.521 28.0271C66.0304 27.9028 65.585 27.6424 65.2361 27.2757C64.8734 26.8392 64.6163 26.325 64.4848 25.7729C64.2711 24.9945 64.1649 24.1906 64.1692 23.3835V23.3835Z"
                fill="black"
            />
            <path
                d="M83.0968 18.289C82.78 18.4496 82.4928 18.663 82.2477 18.9201C81.9595 19.234 81.7074 19.5793 81.4963 19.9495V26.9225H83.48V28.0195H77.5139V26.8924H79.1745V17.3948H77.5139V16.2752H81.5264V18.5294H81.6165C81.8554 18.0628 82.1562 17.6306 82.5107 17.2445C82.8612 16.8735 83.2547 16.5456 83.6829 16.2677H85.8319V18.2814L83.0968 18.289Z"
                fill="black"
            />
            <path
                d="M87.4925 17.3948H86.17V16.2753H91.9558V17.3948H90.0698L93.1205 24.8412L93.5713 26.5468H93.684L94.1123 24.8186L96.5093 17.3948H95.1417V16.2753H99.4472V17.3948H98.012L94.3302 28.0196C94.1376 28.5968 93.9092 29.1614 93.6464 29.7102C93.406 30.2287 93.1655 30.6795 92.9326 31.0778C92.7401 31.4203 92.5136 31.7425 92.2563 32.0395C92.1222 32.2364 91.9481 32.4028 91.7454 32.528H89.4912V30.5142H91.7454C91.8506 30.394 91.9483 30.2738 92.0384 30.1535C92.1348 30.0111 92.2227 29.8631 92.3014 29.7102C92.3916 29.5299 92.4818 29.3045 92.587 29.0415C92.6922 28.7785 92.8124 28.4479 92.9401 28.0496H92.1887L87.4925 17.3948Z"
                fill="black"
            />
            <path
                d="M111.935 23.9245H113.371V27.441C112.793 27.7377 112.175 27.9502 111.537 28.0722C110.796 28.232 110.041 28.3126 109.283 28.3126C108.035 28.3316 106.79 28.1747 105.586 27.8467C104.56 27.5612 103.613 27.0474 102.813 26.3439C102.043 25.6545 101.448 24.7916 101.078 23.8268C100.646 22.6563 100.44 21.4147 100.469 20.1675C100.431 18.8817 100.661 17.6021 101.145 16.4105C101.547 15.4452 102.167 14.5862 102.956 13.9008C103.73 13.2432 104.634 12.7567 105.609 12.4732C106.634 12.1752 107.697 12.0259 108.764 12.0299C109.306 12.0299 109.794 12.0299 110.222 12.0825C110.618 12.1171 111.012 12.1722 111.402 12.2478C111.733 12.3171 112.059 12.4075 112.379 12.5183C112.679 12.6235 112.972 12.7362 113.25 12.8564V16.7486H111.815V14.3892L111.507 13.9384C111.263 13.834 111.012 13.7461 110.756 13.6754C110.189 13.5461 109.608 13.493 109.027 13.5176C108.26 13.5141 107.498 13.6411 106.773 13.8933C106.079 14.1411 105.453 14.5507 104.947 15.088C104.394 15.6824 103.976 16.39 103.723 17.1619C103.404 18.1313 103.252 19.1474 103.272 20.1675C103.153 21.9647 103.737 23.7379 104.902 25.1117C105.496 25.7077 106.21 26.1707 106.996 26.4699C107.783 26.7691 108.623 26.8978 109.463 26.8474C109.92 26.8564 110.376 26.8161 110.823 26.7272C111.115 26.6743 111.393 26.5645 111.642 26.4041L111.95 25.9758L111.935 23.9245Z"
                fill="black"
            />
            <path
                d="M115.843 22.1737C115.824 21.2814 115.966 20.393 116.263 19.5514C116.519 18.8303 116.93 18.1739 117.466 17.6278C117.997 17.1021 118.639 16.7016 119.344 16.4556C120.13 16.1769 120.96 16.0396 121.794 16.0498C122.712 16.0273 123.627 16.1751 124.491 16.4857C125.184 16.7518 125.806 17.1734 126.31 17.7179C126.801 18.2753 127.163 18.9348 127.369 19.649C127.61 20.4685 127.727 21.3196 127.715 22.1737C127.732 23.0604 127.592 23.9431 127.301 24.7811C127.054 25.4984 126.648 26.1507 126.114 26.6896C125.585 27.2192 124.943 27.6225 124.236 27.8693C122.58 28.4349 120.782 28.4349 119.126 27.8693C118.425 27.5943 117.797 27.1595 117.293 26.5994C116.791 26.0489 116.417 25.3948 116.196 24.6834C115.948 23.8703 115.829 23.0236 115.843 22.1737ZM118.352 22.1737C118.347 22.8429 118.415 23.5105 118.555 24.1649C118.669 24.7341 118.894 25.2754 119.216 25.7579C119.522 26.2053 119.932 26.5715 120.411 26.8249C120.966 27.1006 121.58 27.2348 122.199 27.2156C122.636 27.2175 123.065 27.1073 123.447 26.8954C123.829 26.6836 124.149 26.3773 124.378 26.0058C125.037 24.8413 125.335 23.5077 125.235 22.1737C125.24 21.4996 125.172 20.827 125.032 20.1675C124.919 19.5947 124.703 19.0473 124.394 18.552C124.122 18.1173 123.748 17.7561 123.304 17.5C122.788 17.2304 122.211 17.0984 121.628 17.1168C121.165 17.1047 120.706 17.2091 120.294 17.4205C119.882 17.6318 119.529 17.9434 119.269 18.3266C118.582 19.487 118.263 20.8282 118.352 22.1737Z"
                fill="black"
            />
            <path
                d="M142.57 28.0195H137.167V26.8924H138.67V21.4749C138.672 20.9128 138.644 20.351 138.587 19.7918C138.557 19.3662 138.453 18.9491 138.279 18.5595C138.133 18.2454 137.894 17.9833 137.596 17.8081C137.234 17.6224 136.83 17.5343 136.423 17.5526C136.086 17.548 135.751 17.6119 135.439 17.7405C135.143 17.8692 134.871 18.0471 134.635 18.2664C134.397 18.4881 134.19 18.7409 134.019 19.0178C133.844 19.3114 133.703 19.624 133.598 19.9496V26.9H135.101V28.0195H129.608V26.8924H131.269V17.3948H129.608V16.2752H133.621V18.6496C133.881 17.9123 134.354 17.269 134.981 16.8012C135.668 16.2848 136.511 16.0196 137.37 16.0498C137.759 16.0499 138.146 16.0877 138.527 16.1625C138.909 16.2359 139.271 16.388 139.591 16.6093C139.91 16.8305 140.18 17.116 140.383 17.4474C140.645 17.891 140.809 18.3855 140.864 18.8976C141.018 18.5156 141.22 18.1546 141.465 17.8231C141.708 17.4776 142.002 17.171 142.337 16.9139C142.681 16.647 143.063 16.4341 143.471 16.2827C143.909 16.1222 144.373 16.0433 144.839 16.0498C145.381 16.0401 145.921 16.1162 146.439 16.2752C146.885 16.4103 147.281 16.6713 147.582 17.0266C147.925 17.4665 148.159 17.9815 148.265 18.5294C148.432 19.3298 148.508 20.1465 148.491 20.9639V26.9H150.151V28.0195H144.659V26.8924H146.161V21.0466C146.165 20.5442 146.135 20.0421 146.071 19.5438C146.029 19.16 145.914 18.7877 145.733 18.4468C145.572 18.1596 145.332 17.9249 145.042 17.7705C144.668 17.5986 144.258 17.5187 143.847 17.5376C143.503 17.5207 143.159 17.5792 142.84 17.709C142.521 17.8388 142.234 18.0367 141.999 18.289C141.526 18.838 141.198 19.4967 141.044 20.205V26.93H142.615L142.57 28.0195Z"
                fill="black"
            />
            <path
                d="M156.989 32.5279H151.316V31.4008H152.977V17.3948H151.316V16.2752H155.328V18.6271C155.629 17.8853 156.122 17.2369 156.756 16.7486C157.454 16.2605 158.295 16.0174 159.146 16.0573C159.784 16.0202 160.423 16.1233 161.018 16.3596C161.612 16.5958 162.148 16.9595 162.587 17.4249C163.398 18.3341 163.812 19.8293 163.812 21.9332C163.823 22.8398 163.681 23.7418 163.391 24.6007C163.145 25.3517 162.74 26.0411 162.204 26.6219C161.686 27.178 161.049 27.6095 160.34 27.8843C159.567 28.1853 158.743 28.3333 157.913 28.3201H157.117C156.884 28.3201 156.673 28.2825 156.478 28.2525C156.284 28.2234 156.093 28.1781 155.907 28.1172L155.328 27.9143V31.4534H156.989V32.5279ZM158.266 17.5451C157.919 17.5316 157.572 17.587 157.245 17.7082C156.919 17.8294 156.62 18.0141 156.365 18.2514C155.853 18.7912 155.499 19.462 155.344 20.19V26.7647C155.649 26.9574 155.988 27.0901 156.343 27.1554C156.84 27.2322 157.343 27.2649 157.846 27.2531C158.373 27.2666 158.894 27.1312 159.348 26.8624C159.768 26.5764 160.119 26.2019 160.378 25.7654C160.696 25.2452 160.927 24.6767 161.062 24.0822C161.224 23.3726 161.302 22.6462 161.295 21.9182C161.3 21.3284 161.247 20.7395 161.137 20.1599C161.052 19.6776 160.882 19.2144 160.633 18.7924C160.408 18.415 160.086 18.1039 159.702 17.8907C159.261 17.6553 158.766 17.5388 158.266 17.5526V17.5451Z"
                fill="black"
            />
            <path
                d="M176.052 26.5167C175.63 27.0949 175.059 27.547 174.399 27.8242C173.613 28.1593 172.766 28.3232 171.912 28.305C171.016 28.3229 170.124 28.1751 169.282 27.8692C168.563 27.6078 167.913 27.1864 167.381 26.6369C166.857 26.0867 166.462 25.4272 166.224 24.7059C165.95 23.8953 165.815 23.0442 165.825 22.1887C165.825 20.1825 166.329 18.6646 167.328 17.6202C167.889 17.0829 168.555 16.6673 169.285 16.3996C170.014 16.132 170.791 16.018 171.566 16.0648C172.162 16.0648 172.755 16.1405 173.332 16.2902C173.89 16.4365 174.414 16.6919 174.872 17.0416C175.337 17.4068 175.71 17.875 175.962 18.4092C176.261 19.0718 176.405 19.7939 176.383 20.5206C176.382 20.7892 176.367 21.0576 176.337 21.3246C176.307 21.6101 176.262 21.9107 176.202 22.2263H168.335C168.329 22.8324 168.418 23.4358 168.598 24.0146C168.756 24.5303 169.025 25.005 169.387 25.4047C169.755 25.8055 170.21 26.1166 170.717 26.3138C171.313 26.5458 171.949 26.6582 172.588 26.6445C173.15 26.6502 173.708 26.5508 174.234 26.3514C174.671 26.194 175.08 25.9656 175.443 25.6752L176.052 26.5167ZM171.446 17.1168C171.029 17.1087 170.614 17.1775 170.221 17.3196C169.861 17.4592 169.544 17.6927 169.304 17.9959C169.021 18.3642 168.814 18.7858 168.696 19.2357C168.529 19.8618 168.43 20.5043 168.403 21.1518H173.835C173.835 21.024 173.835 20.8813 173.835 20.7385C173.835 20.5957 173.835 20.468 173.835 20.3478C173.877 19.5211 173.668 18.7011 173.234 17.9959C173.037 17.7072 172.769 17.4736 172.456 17.3172C172.143 17.1607 171.795 17.0866 171.446 17.1017V17.1168Z"
                fill="black"
            />
            <path
                d="M192.109 28.0196H186.617V26.8925H188.119V21.2946C188.167 20.3449 187.982 19.398 187.578 18.5369C187.378 18.2021 187.087 17.9315 186.738 17.7571C186.389 17.5827 185.998 17.5118 185.61 17.5526C185.215 17.5483 184.822 17.6171 184.453 17.7555C184.114 17.8832 183.797 18.0632 183.513 18.289C182.962 18.7394 182.552 19.3395 182.334 20.0172V26.9225H183.836V28.0196H178.344V26.8925H180.004V17.3948H178.344V16.2752H182.356V18.6046C182.703 17.8861 183.219 17.2622 183.859 16.7862C184.56 16.2806 185.407 16.0168 186.271 16.0348C186.876 16.0253 187.48 16.096 188.067 16.2452C188.566 16.3702 189.021 16.6302 189.382 16.9966C189.76 17.4305 190.034 17.9442 190.186 18.4994C190.391 19.2837 190.485 20.0932 190.464 20.9038V26.915H192.124L192.109 28.0196Z"
                fill="black"
            />
            <path
                d="M199.984 25.3521C199.984 25.1553 199.945 24.9604 199.867 24.7795C199.79 24.5986 199.676 24.4354 199.533 24.3001C199.207 23.9741 198.841 23.6913 198.444 23.4586C197.993 23.1956 197.504 22.9401 196.986 22.7072C196.473 22.4529 195.985 22.1511 195.528 21.8055C195.085 21.4826 194.705 21.08 194.409 20.6183C194.097 20.1274 193.94 19.554 193.958 18.9727C193.952 18.558 194.062 18.1497 194.273 17.793C194.489 17.4331 194.778 17.1232 195.122 16.8839C195.502 16.6181 195.924 16.4175 196.37 16.2902C196.858 16.1492 197.364 16.0783 197.872 16.0799C198.323 16.0799 198.722 16.0949 199.067 16.1249C199.413 16.1249 199.728 16.1926 200.014 16.2452C200.288 16.2962 200.559 16.3614 200.825 16.4405L201.622 16.6885V19.6941H200.187V17.9433L199.758 17.4624C199.563 17.4173 199.353 17.3798 199.127 17.3497C198.878 17.3171 198.627 17.302 198.376 17.3046C197.809 17.2607 197.243 17.406 196.768 17.7179C196.605 17.8352 196.472 17.9898 196.38 18.1689C196.289 18.3479 196.241 18.5462 196.242 18.7473C196.241 18.942 196.28 19.1348 196.358 19.3134C196.435 19.492 196.55 19.6523 196.693 19.7842C197.028 20.1099 197.404 20.3903 197.812 20.6183C198.263 20.8738 198.752 21.1292 199.27 21.3697C199.781 21.614 200.268 21.9032 200.728 22.2338C201.162 22.5377 201.541 22.9138 201.847 23.3458C202.154 23.7857 202.312 24.3124 202.298 24.8486C202.308 25.3515 202.18 25.8475 201.93 26.2838C201.675 26.7104 201.329 27.0749 200.916 27.3508C200.454 27.6528 199.946 27.8786 199.413 28.0195C198.813 28.1808 198.193 28.2616 197.572 28.26C196.858 28.26 196.148 28.1639 195.461 27.9744C194.894 27.8366 194.352 27.6137 193.853 27.3132V24.3903H195.288V26.0659L195.739 26.5318C195.948 26.6864 196.182 26.8057 196.43 26.8849C196.772 26.9817 197.127 27.0248 197.482 27.0127C198.103 27.0404 198.72 26.8863 199.255 26.5693C199.476 26.4525 199.661 26.2772 199.79 26.0626C199.918 25.848 199.985 25.6022 199.984 25.3521V25.3521Z"
                fill="black"
            />
            <path
                d="M213.885 24.9313C213.885 25.277 213.885 25.6226 213.885 25.9758C213.885 26.3289 213.922 26.6445 213.952 26.9225H215.455V28.0196H211.894L211.668 25.8856H211.555C211.474 26.202 211.334 26.5002 211.142 26.7647C210.931 27.052 210.678 27.3053 210.391 27.5161C210.059 27.7544 209.691 27.9372 209.301 28.0571C208.851 28.1944 208.382 28.2603 207.911 28.2525C207.436 28.2595 206.964 28.1858 206.514 28.0346C206.107 27.8957 205.729 27.6816 205.401 27.4034C205.081 27.1387 204.824 26.8047 204.65 26.4266C204.473 26.0217 204.386 25.5834 204.395 25.1417C204.368 24.504 204.527 23.8724 204.853 23.3233C205.185 22.823 205.655 22.4291 206.205 22.1887C206.923 21.8807 207.683 21.6806 208.46 21.5951C209.502 21.4652 210.55 21.3975 211.6 21.3922C211.63 21.168 211.645 20.9421 211.646 20.716V20.0848C211.691 19.3552 211.527 18.6278 211.172 17.9884C210.981 17.7274 210.726 17.5208 210.43 17.389C210.135 17.2572 209.811 17.2048 209.489 17.237C209.189 17.237 208.873 17.237 208.527 17.2821C208.2 17.3079 207.881 17.3972 207.588 17.5451V19.5664H205.567V17.1469C206.181 16.8374 206.822 16.5858 207.483 16.3955C208.326 16.1556 209.199 16.0417 210.075 16.0573C210.778 16.0304 211.48 16.1401 212.141 16.3804C212.606 16.5592 213.018 16.8541 213.336 17.237C213.613 17.5812 213.799 17.9894 213.877 18.4242C213.959 18.8628 213.999 19.3081 213.997 19.7542C213.997 20.701 213.997 21.6026 213.945 22.4592C213.892 23.3158 213.885 24.1198 213.885 24.9313ZM208.963 26.7948C209.292 26.7983 209.618 26.7397 209.925 26.622C210.439 26.4125 210.885 26.067 211.217 25.6226C211.36 25.4305 211.49 25.2297 211.608 25.0215V22.354C210.815 22.3375 210.022 22.4004 209.241 22.5419C208.741 22.6295 208.258 22.7997 207.813 23.0453C207.507 23.2177 207.26 23.4799 207.107 23.7967C206.98 24.0907 206.916 24.4082 206.919 24.7284C206.904 25.0025 206.943 25.2768 207.036 25.5351C207.129 25.7933 207.273 26.0301 207.46 26.2312C207.658 26.4235 207.894 26.5729 208.153 26.6698C208.411 26.7668 208.687 26.8093 208.963 26.7948V26.7948Z"
                fill="black"
            />
            <path
                d="M216.575 16.2752H218.573V13.4725H220.925V16.2752H224.682V17.7104H220.925V24.2626C220.873 24.9487 220.997 25.6368 221.286 26.2613C221.409 26.4543 221.583 26.6102 221.788 26.7121C221.993 26.814 222.222 26.8582 222.451 26.8399C222.864 26.8528 223.276 26.7705 223.653 26.5994C223.976 26.442 224.288 26.2612 224.585 26.0584L225.141 26.93C224.974 27.0965 224.79 27.2451 224.592 27.3733C224.34 27.5413 224.073 27.6871 223.796 27.8092C223.476 27.9522 223.144 28.0654 222.804 28.1473C222.432 28.238 222.051 28.2834 221.669 28.2825C220.834 28.3441 220.008 28.0741 219.37 27.5311C219.073 27.1773 218.849 26.7678 218.713 26.3265C218.576 25.8853 218.529 25.4212 218.573 24.9614V17.7104H216.575V16.2752Z"
                fill="black"
            />
            <path
                d="M232.256 28.0195H226.576V26.8924H228.236V17.3948H226.576V16.2752H230.596V26.9224H232.256V28.0195ZM227.748 12.6084C227.74 12.4131 227.777 12.2186 227.857 12.04C227.936 11.8615 228.056 11.7037 228.206 11.579C228.552 11.3295 228.967 11.1953 229.394 11.1953C229.82 11.1953 230.235 11.3295 230.581 11.579C230.719 11.7134 230.828 11.874 230.903 12.0515C230.978 12.2289 231.016 12.4195 231.016 12.6121C231.016 12.8047 230.978 12.9953 230.903 13.1728C230.828 13.3502 230.719 13.5109 230.581 13.6453C230.233 13.8894 229.818 14.0204 229.394 14.0204C228.969 14.0204 228.554 13.8894 228.206 13.6453C228.059 13.5226 227.942 13.3683 227.862 13.194C227.783 13.0198 227.744 12.8299 227.748 12.6384V12.6084Z"
                fill="black"
            />
            <path
                d="M234.18 22.1737C234.163 21.2809 234.309 20.3925 234.608 19.5513C234.862 18.831 235.27 18.1746 235.803 17.6278C236.335 17.1037 236.977 16.7034 237.681 16.4556C238.47 16.177 239.302 16.0396 240.138 16.0498C241.057 16.028 241.971 16.1757 242.836 16.4857C243.538 16.7458 244.169 17.168 244.677 17.7179C245.184 18.2712 245.562 18.931 245.781 19.649C246.019 20.4693 246.135 21.3198 246.127 22.1737C246.144 23.0604 246.004 23.9431 245.714 24.7811C245.46 25.4953 245.055 26.1464 244.527 26.6896C243.995 27.216 243.353 27.6188 242.648 27.8693C240.989 28.4349 239.19 28.4349 237.531 27.8693C236.825 27.6092 236.189 27.1872 235.675 26.637C235.141 26.0843 234.743 25.4157 234.511 24.6834C234.27 23.8691 234.159 23.0224 234.18 22.1737ZM236.69 22.1737C236.685 22.8428 236.753 23.5105 236.892 24.1649C237.009 24.7346 237.236 25.2759 237.561 25.7579C237.866 26.2034 238.273 26.5693 238.748 26.8249C239.303 27.1005 239.917 27.2348 240.537 27.2156C240.974 27.2166 241.404 27.1061 241.787 26.8944C242.17 26.6827 242.492 26.3769 242.723 26.0058C243.382 24.8413 243.68 23.5077 243.58 22.1737C243.585 21.4996 243.517 20.827 243.377 20.1675C243.261 19.5942 243.042 19.0468 242.731 18.552C242.452 18.113 242.067 17.7513 241.611 17.5C241.095 17.2314 240.518 17.0994 239.936 17.1168C239.472 17.1055 239.012 17.2102 238.599 17.4214C238.185 17.6327 237.831 17.9438 237.569 18.3266C236.895 19.4911 236.588 20.8321 236.69 22.1737Z"
                fill="black"
            />
            <path
                d="M261.719 28.0196H256.226V26.8925H257.729V21.2946C257.777 20.3449 257.591 19.398 257.188 18.5369C256.988 18.2021 256.696 17.9315 256.347 17.7571C255.998 17.5827 255.607 17.5118 255.219 17.5526C254.824 17.5483 254.432 17.6171 254.062 17.7555C253.723 17.8832 253.406 18.0632 253.123 18.289C252.571 18.7394 252.161 19.3395 251.943 20.0172V26.9225H253.446V28.0196H247.953V26.8925H249.538V17.3948H247.878V16.2752H251.89V18.6046C252.237 17.8861 252.753 17.2622 253.393 16.7862C254.094 16.2806 254.941 16.0168 255.805 16.0348C256.41 16.0253 257.014 16.096 257.601 16.2452C258.1 16.3702 258.555 16.6302 258.916 16.9966C259.294 17.4305 259.569 17.9442 259.72 18.4994C259.926 19.2837 260.019 20.0932 259.998 20.9038V26.915H261.658L261.719 28.0196Z"
                fill="black"
            />
            <path
                d="M12.0223 32.7758C5.57535 29.5223 1.66058 25.0815 0 17.9808C0.586088 25.635 4.59353 31.3056 12.0223 34.9924C19.4687 31.3181 23.4586 25.6376 24.0446 17.9808C22.3841 25.0815 18.4693 29.5223 12.0223 32.7758Z"
                fill="black"
            />
            <path
                d="M8.81384 12.0899C11.3269 14.3456 13.3477 17.0954 14.7499 20.1674C15.4439 17.3623 15.7971 14.4837 15.8018 11.594C15.4762 11.7142 15.1531 11.8194 14.8325 11.9096C13.904 12.3752 12.8851 12.6325 11.8469 12.6637C10.8086 12.6948 9.7761 12.499 8.82136 12.0899H8.81384Z"
                fill="#BE1E20"
            />
            <path
                d="M5.96607 9.86584C3.85523 10.6742 1.85556 11.7472 0.0150146 13.0593C0.120489 17.2128 1.42526 21.2472 3.77199 24.6759C6.49204 29.2368 9.70802 29.7327 9.70802 29.7327C9.87332 28.9062 10.0086 28.1172 10.1138 27.3508C9.66996 27.1397 9.28723 26.8191 9.0017 26.4191C8.80812 26.1451 8.67232 25.8347 8.60256 25.5065C8.53281 25.1784 8.53056 24.8396 8.59595 24.5105C8.75038 23.7435 9.19785 23.0669 9.84327 22.6245C10.0309 22.5007 10.2327 22.3998 10.4444 22.324C10.3542 15.4637 7.89716 11.9472 7.43881 11.3611"
                fill="#BE1E20"
            />
            <path
                d="M12.7136 22.8799H12.2553H11.594C11.1944 22.8885 10.8016 22.9861 10.4444 23.1655L10.2866 23.2632C9.8135 23.5845 9.48458 24.0779 9.3699 24.6382C9.32521 24.8648 9.3268 25.0981 9.37458 25.3241C9.42235 25.5501 9.51533 25.764 9.64792 25.9532C9.80048 26.1723 9.99765 26.3566 10.2265 26.4942C10.8228 26.8231 11.4916 26.9987 12.1726 27.0051C12.7643 25.6563 13.2835 24.2767 13.728 22.8724C13.465 22.895 13.1269 22.895 12.7136 22.8799Z"
                fill="black"
            />
            <path
                d="M11.6316 0C10.3511 0.00342738 9.11117 0.449291 8.12174 1.26209C7.1323 2.07489 6.45418 3.20467 6.20221 4.46011C5.95023 5.71555 6.1399 7.0195 6.73907 8.15114C7.33825 9.28278 8.31012 10.1726 9.49011 10.6698C10.1692 10.9507 10.8967 11.0962 11.6316 11.0981C12.4844 11.0983 13.3256 10.9003 14.0886 10.5195C15.0162 10.0611 15.797 9.35226 16.3428 8.47322C16.8885 7.59417 17.1774 6.57998 17.1769 5.5453C17.1749 4.0752 16.59 2.66589 15.5505 1.62638C14.511 0.586862 13.1017 0.00198796 11.6316 0V0Z"
                fill="black"
            />
            <path
                d="M17.5451 26.9901C17.6277 26.8173 17.7179 26.6595 17.8156 26.4867C18.133 25.9644 18.4215 25.4251 18.6797 24.8712C19.1833 23.7974 19.5566 22.6673 19.7918 21.5049C20.1675 19.5744 20.1675 17.5896 19.7918 15.6591C19.6627 14.9512 19.4844 14.2532 19.2583 13.5702C19.093 13.0667 18.9276 12.6309 18.7699 12.2628C18.4843 11.5114 18.1086 10.6097 17.7179 9.70801C19.9775 10.5383 22.1155 11.6679 24.0747 13.0667C23.9737 15.2941 23.5744 17.4978 22.8875 19.6189C22.2905 21.4706 21.4028 23.2156 20.2576 24.7885C19.325 25.9428 18.2608 26.9843 17.0867 27.8918C17.2536 27.6894 17.3811 27.4574 17.4624 27.208C17.4852 27.1337 17.5128 27.0609 17.5451 26.9901Z"
                fill="#BE1E20"
            />
        </svg>
    );
}
